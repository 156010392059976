function App() {
  const login = { username: 'b1gd3vd0g', password: 'JHBPmttydP5046!' };

  const objToString = (obj) => {
    let str = '{ ';
    Object.keys(obj).forEach((key) => {
      str += key + ': ' + obj[key] + ', ';
    });
    return str + ' }';
  };

  const requestUserLogin = async () => {
    const response = await fetch('https://www.api.bigdevdog.com:6905/session', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(login)
    });
    const obj = await response.json();
    document.querySelector('#ulog').innerHTML = objToString({
      ...obj,
      code: response.status
    });
  };

  const requestProjectLogin = async () => {
    const response = await fetch('https://www.api.bigdevdog.com:6907/session', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(login)
    });
    const obj = await response.json();
    document.querySelector('#plog').innerHTML = objToString({
      ...obj,
      code: response.status
    });
  };

  const checkUserLogin = async () => {
    const response = await fetch('https://www.api.bigdevdog.com:6905/session', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'GET'
    });
    const obj = await response.json();
    document.querySelector('#ucheck').innerHTML = objToString({
      ...obj,
      code: response.status
    });
  };

  const checkProjectLogin = async () => {
    const response = await fetch('https://www.api.bigdevdog.com:6907/session', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'GET'
    });
    const obj = await response.json();
    document.querySelector('#pcheck').innerHTML = objToString({
      ...obj,
      code: response.status
    });
  };

  return (
    <div className='App'>
      <button onClick={requestProjectLogin}>Login to project</button>
      <span id='plog'></span>
      <br />
      <button onClick={requestUserLogin}>Login to user</button>
      <span id='ulog'></span>
      <br />
      <button onClick={checkProjectLogin}>check project</button>
      <span id='pcheck'></span>
      <br />
      <button onClick={checkUserLogin}>check user</button>
      <span id='ucheck'></span>
    </div>
  );
}

export default App;
